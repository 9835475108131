import * as React from 'react';
import {
  FormProvider,
  FormSubmit,
  Input,
  useFormStatus,
  validations,
} from '@web/components/form';
import Link from 'next/link';
import { fadeInDown, theme, variantProps } from '@web/features/theme';
import { motion } from 'framer-motion';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useSearchParams } from 'next/navigation';
import { TMApi } from '@web/services/tmapi';
import { Info } from './info';
import { styles } from './sign-in.styles';
import { useAuth } from './use-auth';

export type SignInProps = { redirectTo?: string | null };

/**
 * # SignIn
 *
 * Component displaying a form that allows a user to sign in.
 * Also allows a user to reset their password.
 *
 * @remarks
 * Uses {@link useAuth} to access `signIn` and `resetPassword` function
 * Also links to {@link TermsAndConditions} and {@link SignUp}
 *
 * @export
 * @param {SignInProps} { redirectTo = null }
 * @return {JSX.Element}
 */
export function SignIn({ redirectTo = null }: SignInProps) {
  const { signIn, resetPassword } = useAuth()[1];
  const [isResetting, setIsResetting] = React.useState(false);
  const [status, setStatus] = useFormStatus();

  const [showPassword, setShowPassword] = React.useState(false);

  const toggleShowPassword = () => setShowPassword((prev) => !prev);

  const passwordType = showPassword ? 'text' : 'password';
  const searchParams = useSearchParams();

  const onAuthSubmit: FormSubmit<{
    email: string;
    password: string;
    shouldRemember: boolean;
  }> = async ({ email, password, shouldRemember }) => {
    setStatus({ type: 'info', el: 'signing in...' });

    try {
      await signIn(email, password, shouldRemember, redirectTo);
      return setStatus({ type: 'success', el: 'Sign in successful.' });
    } catch (error) {
      const cleanedError = error as Error;
      return setStatus({ type: 'error', el: cleanedError.message });
    }
  };

  const onResetSubmit = async ({ email }: { email: string }) => {
    try {
      await resetPassword(email);
      return setStatus({
        type: 'success',
        el: 'Check your email for a password rest link.',
      });
    } catch (error) {
      const cleanedError = error as Error;
      return setStatus({ type: 'error', el: cleanedError.message });
    }
  };

  React.useEffect(() => {
    if (window === undefined) {
      return;
    }
    const utmSource = searchParams.get('utm_source');
    const utmCampaign = searchParams.get('utm_campaign');
    const ref = searchParams.get('ref');

    if (utmSource) {
      window.sessionStorage.setItem('utm_source', utmSource);
      const tmapi = new TMApi();
      tmapi.sendUTMPoint(
        window.location.href,
        { utm_source: utmSource, utm_campaign: utmCampaign },
        ref,
      );
    }
    if (utmCampaign) {
      window.sessionStorage.setItem('utm_campaign', utmCampaign);
    }
  }, [searchParams]);

  return (
    <section css={{ maxWidth: '36rem', margin: '0 auto' }}>
      <motion.header
        css={{ margin: '0 auto' }}
        variants={fadeInDown}
        {...variantProps}
      >
        <h1 css={styles.title}>
          {isResetting
            ? 'Reset your password.'
            : 'Welcome, your time machine awaits!'}
        </h1>
        <p css={styles.subtitle}>
          {isResetting
            ? 'Enter your email to get a reset password link.'
            : 'Sign in to love and connect with yourself over time.'}
        </p>
      </motion.header>

      {isResetting ? (
        <FormProvider
          status={status}
          defaultValues={{ email: '' }}
          onSubmit={onResetSubmit}
        >
          <div />

          <Input
            name="email"
            label="Email"
            placeholder="Enter your email"
            autoFocus
            config={{
              required: validations.required,
              pattern: validations.email,
            }}
          />
        </FormProvider>
      ) : (
        <FormProvider
          defaultValues={{ email: '', password: '', shouldRemember: true }}
          onSubmit={onAuthSubmit}
          status={status}
          submitButtonText="Sign in"
        >
          <Input
            name="email"
            type="email"
            label="Email"
            placeholder="Enter your email"
            autoFocus
            config={{
              required: validations.required,
              pattern: validations.email,
            }}
          />

          <Input
            name="password"
            type={passwordType}
            label="Password"
            placeholder="Enter your password"
            config={{
              required: validations.required,
              minLength: validations.minLength,
              maxLength: validations.maxLength,
            }}
            rightIcon={showPassword ? <FaEye /> : <FaEyeSlash />}
            onRightIconClick={toggleShowPassword}
          />

          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Input name="shouldRemember" type="checkbox" label="Remember Me" />

            <button
              type="button"
              key="reset"
              onClick={() => setIsResetting(!isResetting)}
              css={[styles.forgotPassword, { textAlign: 'right', flex: 1 }]}
            >
              {isResetting ? 'Go back to login' : 'Forgot Password'}
            </button>
          </div>
        </FormProvider>
      )}

      <Link
        href="/register"
        passHref
        css={{
          display: 'block',
          textAlign: 'center',
          color: theme.colors.info,
          marginTop: -'10%',
        }}>
        
          Register
        
      </Link>

      <Info />
    </section>
  );
}
